import { Link } from "gatsby";
import React from "react";
import Layout from '../components/layout';

export default function Brands({ data }) {
  return (
    <div>
      <Layout>
        <h1>Tut mir leid, ich konnte diese Seite nicht finden.</h1>
        <Link to="/">Zurück zur Startseite</Link>
      </Layout>
    </div>
  )
}